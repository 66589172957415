
import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readUserProfile, readUserRoles } from '@/store/main/getters';

@Component
export default class Dashboard extends Vue {

  public get hasVTSAccess() {
    return ['VTS', 'ADMIN'].some((r) => this.userRoles!.includes(r))
  }
  public get hasT1Access() {
    return ['T1', 'ADMIN'].some((r) => this.userRoles!.includes(r))
  }
  public get hasT2Access() {
    return ['T2', 'ADMIN'].some((r) => this.userRoles!.includes(r))
  }

  public get userRoles() {
    return readUserRoles(this.$store);
  }

  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.full_name) {
        return userProfile.full_name;
      } else {
        return userProfile.email;
      }
    }
  }
}
